import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styles from "../pages/partners.module.css"
import Layout from '../components/Layout'
//import Content, { HTMLContent } from '../components/Content'
import { HTMLContent } from '../components/Content'



//console.log(styles)

export const PartnerPageTemplate = ({ title, partners, content, contentComponent}) => {
  //const PageContent = contentComponent || Content

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              {partners.map(partner => (
                <div className={styles.user}>
                  <img src={partner.avatar} className={styles.avatar} alt="" />
                  <div className={styles.description}>
                    <h2 className={styles.username}>{partner.name}</h2>
                    <p className={styles.excerpt}>{partner.excerpt}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

PartnerPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  partners: PropTypes.object,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const PartnerPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout pageMetadata = {post.frontmatter.pageMetadata}>
      <PartnerPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        partners={post.frontmatter.partners}
        content={post.html}
      />
    </Layout>
  )
}


PartnerPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PartnerPage

export const partnerPageQuery = graphql`
  query PartnerPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        pageMetadata {
          title
          description
        }
        title
        partners{
          name
          avatar
          excerpt
        }
      }
    }
  }
`
